<template>
  <div>
    <a-card class="card" title="基本信息" :bordered="false">
      <div slot="extra">
        <a-button ><router-link :to="{ name: 'InChargeRule'}">取消</router-link></a-button>
        <a-divider type="vertical" />
        <a-button type="primary" @click="handleSubmit">保存</a-button>
      </div>
      <a-form-model layout="vertical" :model="form"
                    :rules="rules" ref="form">
        <a-row :gutter="20">
          <a-col :span="8">
            <a-form-model-item label="客户" prop="customer_id">
              <a-select
                showSearch
                allowClear
                ref="customer_id"
                placeholder="请选择客户名称"
                style="width: 100%"
                v-model="form.customer_id"
              >
                <a-select-option v-for="op in customerOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="进仓编号关键字">
              <a-input v-model="form.in_num_pattern" />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="20">
          <a-col :span="8">
            <a-form-model-item label="最低收费">
              <a-input v-model="form.min_charge" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="白班时间">
              <a-input-group compact>
                <a-input-number v-model="form.day_work_time.from" style=" width: 45%; text-align: center" placeholder="from" />
                <a-input
                  style=" width: 10%; border-left: 0; pointer-events: none; backgroundColor: #fff"
                  placeholder="-"
                  disabled
                />
                <a-input-number v-model="form.day_work_time.to" style="width: 45%; text-align: center; border-left: 0" placeholder="to" />
              </a-input-group>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-card>
    <a-card class="card" title="费用设置" :bordered="false">
      <a-table bordered :dataSource="feeData"
               :columns="feeColumns" :pagination="false"
               rowKey="id" size="small">
        <template slot="fee_id" slot-scope="text, record, index">
          <a-select placeholder="" :value="text"
                    :ref="'fee_id' + index"
                    class="full-width" @change="e => handleChange(e, record.id, 'fee_id', index)">
            <a-select-option v-for="d in feeTypeOps" :key="d.value">{{ d.name }}</a-select-option>
          </a-select>
        </template>
        <template slot="vehicle_type_id" slot-scope="text, record, index">
          <a-select placeholder="" :value="text"
                    :ref="'vehicle_type_id' + index"
                    class="full-width" @change="e => handleChange(e, record.id, 'vehicle_type_id', index)">
            <a-select-option v-for="d in vecicleTypeOps" :key="d.value">{{ d.name }}</a-select-option>
          </a-select>
        </template>
        <template slot="dper_piece" slot-scope="text, record">
          <a-input :value="text"
                   @change="e => handleChange(e.target.value, record.id, 'dper_piece')"/>
        </template>
        <template :slot="cl" v-for="cl in ['day_fee_info.per_piece', 'day_fee_info.per_ton', 'day_fee_info.per_volume', 'day_fee_info.per_tuo'
                                           ,'night_fee_info.per_piece', 'night_fee_info.per_ton', 'night_fee_info.per_volume', 'night_fee_info.per_tuo', 'fixed_amount']"
                  slot-scope="text, record, index">
          <a-input :value="text" :key="cl + index"
                   @change="e => handleChange(e.target.value, record.id, cl)"/>
        </template>
        <template slot="operation" slot-scope="text, record">
          <a-popconfirm
            v-if="feeData.length"
            title="确认删除?"
            @confirm="() => onDelete(record.id)"
          >
            <a href="javascript:;"><a-icon type="delete" :style="{ fontSize: '18px' }"/></a>
          </a-popconfirm>
        </template>
      </a-table>
      <a-button style="width: 100%; margin-top: 16px; margin-bottom: 8px" type="dashed" icon="plus" @click="handleAdd">新增</a-button>
    </a-card>
  </div>
</template>

<script>
import { getCommonOptions } from '@/api/common'
import { getWmsDataOption, saveWmsDataInfo, getWmsDataFeeList } from '@/api/wms'
export default {
  data() {
    return {
      form: {
        customer_id: '',
        in_num_pattern: '',
        min_charge: '',
        day_work_time: {
          from: null,
          to: null
        }
      },
      rules: {
        customer_id: [
          { required: true }
        ]
      },
      isEdit: false,
      customerOps: [],
      feeTypeOps: [],
      vecicleTypeOps: [],
      formInfo: null,
      feeData: [],
      columns: [
        {
          title: '操作',
          scopedSlots: { customRender: 'operation' },
          align: 'center',
          width: 60
        },
        {
          title: '费用名称',
          dataIndex: 'fee_id',
          width: 80,
          scopedSlots: { customRender: 'fee_id' }
        },
        {
          title: '车型',
          dataIndex: 'vehicle_type_id',
          width: 80,
          scopedSlots: { customRender: 'vehicle_type_id' }
        },
        {
          title: '固定金额',
          dataIndex: 'fixed_amount',
          width: 100,
          scopedSlots: { customRender: 'fixed_amount' }
        }
      ],
      feeColumns: [
        {
          title: '费用名称',
          dataIndex: 'fee_id',
          width: 120,
          scopedSlots: { customRender: 'fee_id' }
        },
        {
          title: '车型',
          dataIndex: 'vehicle_type_id',
          width: 120,
          scopedSlots: { customRender: 'vehicle_type_id' }
        },
        {
          title: '白班',
          dataIndex: 'day_fee_info',
          children: [
            {
              title: '元/件',
              dataIndex: 'day_fee_info.per_piece',
              scopedSlots: { customRender: 'day_fee_info.per_piece' }
            },
            {
              title: '元/吨',
              dataIndex: 'day_fee_info.per_ton',
              scopedSlots: { customRender: 'day_fee_info.per_ton' }
            },
            {
              title: '元/体积',
              dataIndex: 'day_fee_info.per_volume',
              scopedSlots: { customRender: 'day_fee_info.per_volume' }
            },
            {
              title: '元/托',
              dataIndex: 'day_fee_info.per_tuo',
              scopedSlots: { customRender: 'day_fee_info.per_tuo' }
            }
          ]
        },
        {
          title: '夜班',
          dataIndex: 'night_fee_info',
          children: [
            {
              title: '元/件',
              dataIndex: 'night_fee_info.per_piece',
              scopedSlots: { customRender: 'night_fee_info.per_piece' }
            },
            {
              title: '元/吨',
              dataIndex: 'night_fee_info.per_ton',
              scopedSlots: { customRender: 'night_fee_info.per_ton' }
            },
            {
              title: '元/体积',
              dataIndex: 'night_fee_info.per_volume',
              scopedSlots: { customRender: 'night_fee_info.per_volume' }
            },
            {
              title: '元/托',
              dataIndex: 'night_fee_info.per_tuo',
              scopedSlots: { customRender: 'night_fee_info.per_tuo' }
            }
          ]
        },
        {
          title: '固定金额',
          dataIndex: 'fixed_amount',
          scopedSlots: { customRender: 'fixed_amount' }
        }
      ]
    }
  },
  watch: {
    $route: function(newRoute) {
      if (!this.queryParam && newRoute.name === 'InChargeRuleCur') { // 编辑页路由监控
        if (newRoute.params.id === 'create') { // 新增
          this.isEdit = false
          this.$route.meta.title = '新增入库费用规则'
          this.$refs.form.resetFields()
          this.feeData = []
        } else {
          this.getEditData(newRoute.params)
        }
      }
    }
  },
  created() {
    // getCommonOptions('customer').then(v => {
    //   this.customerOps = v
    // })
    getCommonOptions('fee').then(v => {
      this.feeTypeOps = v
    })
    getWmsDataOption('vehicle_type').then(v => {
      this.vecicleTypeOps = v
    })
  },
  mounted() {
    if (this.$route.params.id !== 'create') {
      this.getEditData(this.$route.params)
    }
  },
  methods: {
    getEditData(record) {
      this.isEdit = true
      this.form = { ...record }
      if (record.day_work_time) {
        const times = record.day_work_time.split('-')
        this.form.day_work_time = {
          from: times[0],
          to: times[[1]]
        }
      }
      getWmsDataFeeList('in_charge_rule', { id: record.id }).then(v => {
        this.feeData = v
      })
      this.$route.meta.title = '编辑入库费用规则'
    },
    handleSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          const data = {
            rule: { ...this.form, customer_name: this.$refs['customer_id'].$el.innerText },
            fee_list: this.feeData
          }
          saveWmsDataInfo('in_charge_rule', data).then(_ => {
              this.confirmLoading = false
              this.formVisible = false
              this.$notification['success']({
                message: '提示',
                description: this.isEdit ? '编辑成功' : '创建成功.'
              })
              this.$router.push({
                name: 'InChargeRule',
                params: { ...data.rule, day_work_time: (data.rule.from || '') + '-' + (data.rule.to || '') },
                query: {
                  isEdit: this.isEdit
                }
              })
            }).catch(_ => {
              this.formVisible = false
              this.confirmLoading = false
            })
        } else {
          return false;
        }
      })
    },
    handleAdd() {
      const { feeData } = this;
      const newData = {
        id: Date.now(),
        fee_id: null,
        vehicle_type_id: null,
        day_fee_info: {
          per_piece: null,
          per_ton: null,
          per_volume: null,
          per_tuo: null
        },
        night_fee_info: {
          per_piece: null,
          per_ton: null,
          per_volume: null,
          per_tuo: null
        },
        fixed_amount: null
      };
      this.feeData = [...feeData, newData]
    },
    onDelete(key) {
      const data = [...this.feeData];
      this.feeData = data.filter(item => item.id !== key);
    },
    handleChange (value, key, column) {
      const newData = [...this.feeData]
      const target = newData.find(item => key === item.id)
      if (target) {
        const cls = column.split('.')
        if (cls.length > 1) {
          target[cls[0]][cls[1]] = value
        } else {
          target[column] = value
        }
        this.feeData = newData
      }
      console.log(this.feeData)
    }
  }
}
</script>
